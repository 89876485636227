<template>
    <div>
        <template v-if="!isMobile">
            <b-table borderless
                     :fields="existingPoolsFields"
                     :items="pools"
                     tbody-tr-class="hover">

                <template v-slot:cell(tokenPools)="{item}">
                    <div class="td-link">
                        <TokenPair
                                :tFrom=item.pair[0]
                                :tTo=item.pair[1]
                        ></TokenPair>
                    </div>
                </template>
                <template v-slot:cell(liquidity)="{item}">
                    <div>
                        <template v-if="item.amount_symbol === '$'">$</template>
                        {{ item.liquidity }}
                        <template v-if="item.amount_symbol !== '$'">{{ item.amount_symbol }}</template>
                    </div>
                </template>
                <template v-slot:cell(liquidity)="{item}">
                    <div>
                        <template v-if="item.amount_symbol === '$'">$</template>
                        {{ item.liquidity }}
                        <template v-if="item.amount_symbol !== '$'">{{ item.amount_symbol }}</template>
                    </div>
                </template>
                <template v-slot:cell(volumesDay)="{item}">
                    <div>
                        <template v-if="item.amount_symbol === '$'">$</template>
                        {{ item.volumes_day }}
                        <template v-if="item.amount_symbol !== '$'">{{ item.amount_symbol }}</template>
                    </div>
                </template>
                <template v-slot:cell(volumesWeek)="{item}">
                    <div>
                        <template v-if="item.amount_symbol === '$'">$</template>
                        {{ item.volumes_week }}
                        <template v-if="item.amount_symbol !== '$'">{{ item.amount_symbol }}</template>
                    </div>
                </template>
                <template v-slot:cell(annualRate)="{item}">
                    <div>
                        +{{ item.annual_rate }}%
                    </div>
                </template>
                <template v-slot:cell(action)="{item}">
                    <div>
                        <div class="buttons">
                            <button class="button-stroked button-small" v-on:click="navigateToAddLiquidity(item)">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16ZM8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4Z"
                                          fill="#B9F0EE"/>
                                    <path d="M6.82843 9.07109L4.58579 9.07109C3.9665 9.07109 3.46447 8.56906 3.46447 7.94977C3.46447 7.33048 3.9665 6.82845 4.58579 6.82845L6.82843 6.82845L6.82843 4.58581C6.82843 3.96652 7.33046 3.46449 7.94975 3.46449C8.56903 3.46449 9.07107 3.96652 9.07107 4.58581L9.07107 6.82845L11.3137 6.82845C11.933 6.82845 12.435 7.33048 12.435 7.94977C12.435 8.56906 11.933 9.07109 11.3137 9.07109L9.07107 9.07109L9.07107 11.3137C9.07107 11.933 8.56903 12.435 7.94975 12.435C7.33046 12.435 6.82843 11.933 6.82843 11.3137L6.82843 9.07109Z"
                                          fill="#0BB7AF"/>
                                </svg>
                                Add liquidity
                            </button>
                            <button class="button-flat button-small" v-on:click="navigateToSwap(item)">Trade</button>
                        </div>
                    </div>
                </template>


            </b-table>

        </template>

        <template v-if="isMobile">
            <div class="pool" v-for="(item, index) in pools" :key="'pool'+index">
                <div class="pool-header">
                    <TokenPair
                            :tFrom=item.pair[0]
                            :tTo=item.pair[1]
                    ></TokenPair>
                    <div class="pool-row">
                        <div class="caption mr-3"> Annual rate</div>
                        +{{ item. annual_rate}}%
                    </div>
                </div>
                <div class="pool-body">
                    <div class="pool-row">
                        <div class="caption mr-3">Liquidity:</div>
                        <template v-if="item.amount_symbol === '$'">$</template>
                        {{ item.liquidity}}
                        <template v-if="item.amount_symbol !== '$'">{{ item.amount_symbol }}</template>
                    </div>
                    <div class="pool-row">
                        <div class="caption mr-3">Volumes (24h):</div>
                        <template v-if="item.amount_symbol === '$'">$</template>
                        {{ item.volumes_day}}
                        <template v-if="item.amount_symbol !== '$'">{{ item.amount_symbol }}</template>
                    </div>
                    <div class="pool-row">
                        <div class="caption mr-3">Volumes (7d):</div>
                        <template v-if="item.amount_symbol === '$'">$</template>
                        {{ item.volumes_week}}
                        <template v-if="item.amount_symbol !== '$'">{{ item.amount_symbol }}</template>
                    </div>
                    <div class="buttons">
                        <button class="button-stroked button-small" v-on:click="navigateToAddLiquidity(item)">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                        d="M8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16ZM8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4Z"
                                        fill="#B9F0EE"/>
                                <path
                                        d="M6.82843 9.07109L4.58579 9.07109C3.9665 9.07109 3.46447 8.56906 3.46447 7.94977C3.46447 7.33048 3.9665 6.82845 4.58579 6.82845L6.82843 6.82845L6.82843 4.58581C6.82843 3.96652 7.33046 3.46449 7.94975 3.46449C8.56903 3.46449 9.07107 3.96652 9.07107 4.58581L9.07107 6.82845L11.3137 6.82845C11.933 6.82845 12.435 7.33048 12.435 7.94977C12.435 8.56906 11.933 9.07109 11.3137 9.07109L9.07107 9.07109L9.07107 11.3137C9.07107 11.933 8.56903 12.435 7.94975 12.435C7.33046 12.435 6.82843 11.933 6.82843 11.3137L6.82843 9.07109Z"
                                        fill="#0BB7AF"/>
                            </svg>
                            Add liquidity
                        </button>
                        <button class="button-flat button-small" v-on:click="navigateToSwap(item)">Trade</button>
                    </div>
                </div>
            </div>

        </template>
        <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler" v-if="scroll">
            <span slot="no-more"></span>
            <div slot="no-results"></div>
        </infinite-loading>

    </div>
</template>

<script>
    export default {
        name: "PoolsList",
        props: {
            pools: Array,
            scroll: Boolean,
        },
        components: {
            TokenPair: () => import("@/components/form/TokenPair"),
        },
        computed: {
            isMobile() {
                return window.innerWidth <= 768;
            }
        },
        data: function () {
            return {
                existingPoolsFields: [
                    {key: 'tokenPools', thClass: 'caption'},
                    {key: 'liquidity', thClass: 'caption'},
                    {key: 'volumesDay', label: 'Volumes (24h)', thClass: 'caption'},
                    {key: 'volumesWeek', label: 'Volumes (7d)', thClass: 'caption'},
                    {key: 'annualRate', thClass: 'caption'},
                    {key: 'action', thClass: 'action'}
                ],
                page: 1,
                size: 10,
                infiniteId: +new Date()
            }
        },
        methods: {
            navigateToAddLiquidity(pool) {
                this.$router.push(`/liquidity/pool?f=${pool.pair[0]}&t=${pool.pair[1]}`)
            },
            navigateToSwap(pool) {
                this.$router.push(`/swap?f=${pool.pair[0]}&t=${pool.pair[1]}`)
            },
        }
    }
</script>

<style scoped>

</style>
